<template>
    <div class="matchingApproval" v-loading="isLoading">
        <div class="content_t">
            <div class="header_wrapper">
                <h4 class="head el_left">
                    生产任务信息<strong>（{{ task_number }}）</strong>
                </h4>
                <div class="save el_right" v-if="isReadOnly == false">
                    <el-button type="primary" size="small" @click="approval(1)">
                        审批通过
                    </el-button>
                    <el-button type="danger" size="small" @click="approval(2)">
                        审批不通过
                    </el-button>
                </div>
                <table>
                    <tbody>
                        <tr>
                            <th>工程名称：</th>
                            <td>
                                <el-input disabled v-model="productFormula.project_name" placeholder="请输入内容"></el-input>
                            </td>
                            <th>工程部位：</th>
                            <td>
                                <el-input disabled v-model="productFormula.place_detail" placeholder="请输入内容"></el-input>
                            </td>
                            <th>产品标号：</th>
                            <td>
                                <el-input disabled v-model="productFormula.mark_number" placeholder="请输入内容"></el-input>
                                <input type="hidden" name="" v-model="productFormula.strength_grade">
                            </td>
                            <th>坍落度：</th>
                            <td>
                                <el-input disabled v-model="productFormula.slump" placeholder="请输入内容"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>浇筑方式：</th>
                            <td>
                                <el-input disabled v-model="productFormula.pouring_name" placeholder="请输入内容"></el-input>
                                <input type="hidden" name="" v-model="productFormula.pouring">
                            </td>
                            <th>是否出资料：</th>
                            <td>
                                <el-radio-group v-model="productFormula.is_datum" disabled>
                                    <el-radio :label="true">
                                        是
                                    </el-radio>
                                    <el-radio :label="false">
                                        否
                                    </el-radio>
                                </el-radio-group>
                            </td>
                            <th>产生抗压试验：</th>
                            <td>
                                <el-radio-group v-model="productFormula.is_testblock" disabled>
                                    <el-radio :label="true">
                                        是
                                    </el-radio>
                                    <el-radio :label="false">
                                        否
                                    </el-radio>
                                </el-radio-group>
                            </td>
                            <th>试块组数：</th>
                            <td>
                                <el-input
                                    v-model.number="productFormula.block_group"
                                    placeholder="请输入内容"
                                    disabled
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>备注：</th>
                            <td colspan="5">
                                <el-input
                                    placeholder="备注"
                                    disabled
                                    v-model="productFormula.remarks"
                                    style="width: 100%"
                                >
                                </el-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="content_b">
            <div class="headBox">
                <div class="el_left">
                    <h4 class="head">
                        关联配比
                    </h4>
                </div>
            </div>
            <div class="production_wrapper">
                <span class="production_span">生产机组：</span>
                <div class="radio_list">
                    <el-radio-group v-model="mixstationChecked" @change="mixstationChange">
                        <el-radio
                            v-for="item in mixstationlineData"
                            :key="item.line_code"
                            :label="item.line_code"
                            border
                            :class="lineClass(item.is_adjust_stock)"
                        >
                            {{ item.line_name }}
                            <i class="icon toSave" title="已保存" v-if="item.product_formula_number_hjt && !item.sync_status"></i>
                            <i class="icon toSend" title="已发送" v-if="item.sync_status"></i>
                        </el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="main_header">
                <div class="matching_type_wrapper">
                    <span>配比类型：</span>
                    <div class="radio_list">
                        <el-radio-group v-model="formulaTypeChecked" @change="mixstationChange">
                            <el-radio
                                v-for="item in formulaTypeData"
                                :label="item.value"
                                :key="item.value"
                            >
                                {{ item.label }}
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="statisticalValue el_right">
                    <ul>
                        <li class="sp1">
                            <span>设计容重</span>
                            <p>{{ productFormulaCount.design_volume_weight }}<i>kg</i></p>
                        </li>
                        <li class="sp5">
                            <span>实际容重</span>
                            <p>{{ productFormulaCount.practical_volume_weight }}<i>kg</i></p>
                        </li>
                        <li class="sp2">
                            <span>坍落度</span>
                            <p>{{ productFormulaCount.slump }}<i>mm</i></p>
                        </li>
                        <li class="sp3">
                            <span>水胶比</span>
                            <p>{{ productFormulaCount.water_binder_ratio }}<i>%</i></p>
                        </li>
                        <li class="sp4">
                            <span>砂率</span>
                            <p>{{ productFormulaCount.sand_rate }}<i>%</i></p>
                        </li>
                        <li class="sp5">
                            <span>最大粒径</span>
                            <p>{{ productFormulaCount.max_particle_size }}<i>mm</i></p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="content_table">
                <div class="table_header">
                    <table>
                        <thead>
                            <tr>
                                <th style="width:1rem;">
                                    工控编号
                                </th>
                                <th style="width:1rem;">
                                    工控料仓类型
                                </th>
                                <th style="width:1.1rem;">
                                    原料分类
                                </th>
                                <th style="width:1.1rem;">
                                    原料子类
                                </th>
                                <th style="width:1.1rem;">
                                    原料规格
                                </th>
                                <th style="width:1rem;">
                                    试验配比用量
                                    (kg)
                                </th>
                                <th style="width:1rem;">
                                    施工配比用量
                                    (kg)
                                </th>
                                <th style="width:1rem;">
                                    含水率(%)
                                </th>
                                <th></th>
                                <th style="width:1rem;">
                                    原料分类
                                </th>
                                <th style="width:1rem;">
                                    原料子类
                                </th>
                                <th style="width:1rem;">
                                    原料规格
                                </th>
                                <th style="width:1rem;">
                                    差值
                                </th>
                                <th style="width:1rem;">
                                    施工配比用量
                                    (kg)
                                </th>
                                <th style="width:1rem;">
                                    理论配比用量
                                    (kg)
                                </th>
                                <th style="width:0.9rem;">
                                    最小值(kg)
                                </th>
                                <th style="width:0.9rem;">
                                    最大值(kg)
                                </th>
                                <th style="width:10px;"></th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="table_body">
                    <table>
                        <tbody>
                            <template v-for="(item,index) in tableData">
                                <tr :key="index">
                                    <td style="width:8.3rem;" colspan="8">
                                        <table>
                                            <tbody>
                                                <tbody>
                                                    <template v-for="(k,i) in item.constructionRatio">
                                                        <tr :key="i">
                                                            <td style="width:1rem;" :title="k.plcw">
                                                                <p style="width:1rem;">
                                                                    {{ k.plcw }}
                                                                </p>
                                                            </td>
                                                            <td
                                                                style="width:1rem; color: blue; text-decoration: underline;cursor: pointer"
                                                                :title="k.plcwname"
                                                                @click="JumpChange(k)"
                                                            >
                                                                {{ k.plcwname }}
                                                            </td>
                                                            <td style="width:1.1rem;" title="原料分类">
                                                                {{ k.stock_category_name }}
                                                            </td>
                                                            <td style="width:1.1rem;" title="原料子类">
                                                                {{ k.material_child_name }}
                                                            </td>
                                                            <td style="width:1.1rem;" title="原料规格">
                                                                {{ k.material_name }}
                                                            </td>
                                                            <td style="width:1rem;" title="试验配比用量(kg)">
                                                                {{ k.experiment_quantity }}
                                                            </td>
                                                            <td style="width:1rem;" title="施工配比用量(kg)">
                                                                {{ k.product_quantity }}
                                                            </td>
                                                            <td style="width:1rem;" title="含水率(%)">
                                                                {{ k.moisture_ratio }}
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td></td>
                                    <td style="width:1rem;" title="原料分类">
                                        {{ item.stock_category_name }}
                                    </td>
                                    <td style="width:1rem;" title="原料子类">
                                        {{ item.material_child_name }}
                                    </td>
                                    <td style="width:1rem;" title="原料规格">
                                        {{ item.material_name }}
                                    </td>
                                    <td
                                        title="差值"
                                        style="width:1rem;"
                                        :class="[ item.difference_quantity < 0 ? 'red' : 'green' ]"
                                    >
                                        {{ item.difference_quantity }}
                                    </td>
                                    <td
                                        title="施工配比用量（kg）"
                                        style="width:1rem;"
                                        :class="{'overrun': item.product_quantity
                                            && item.product_quantity < item.theory_quantity_min
                                            && item.product_quantity > item.theory_quantity_max}"
                                    >
                                        <i class="iconfont iconyujing"></i>
                                        {{ item.product_quantity }}
                                    </td>
                                    <td style="width:1rem;" title="理论配比用量(kg)">
                                        {{ item.theory_quantity }}
                                    </td>
                                    <td style="width:0.9rem;" title="最小值(kg)">
                                        {{ item.theory_quantity_min }}
                                    </td>
                                    <td style="width:0.9rem;" title="最大值(kg)">
                                        {{ item.theory_quantity_max }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <el-dialog
            title="审批意见"
            :visible.sync="dialogVisible"
            width="30%"
            custom-class="mixtureRatioApprovalCommentsDialog"
            append-to-body
        >
            <el-input
                type="textarea"
                :autosize="{ minRows: 4 }"
                placeholder="请输入内容"
                v-model="opinion"
            >
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="approvalCancelled" size="small">取 消</el-button>
                <el-button type="primary" @click="approvalConfirmation" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        extr: {
            type: [Array, Object, String],
        },
    },
    data() {
        return {
            // 施工配比基本信息存储
            productFormula: {
                project_name: '',
                project_detail: '',
                is_datum: false,
                is_testblock: false,
            },
            // 全部生产机组
            mixstationlineData: [],
            // 机组选中项code
            mixstationChecked: null,
            // 全部配比类型
            formulaTypeData: [
                {
                    label: '砼配比',
                    value: 0,
                },
                {
                    label: '润泵砂浆配比',
                    value: 1,
                },
            ],
            // 配比类型选中项
            formulaTypeChecked: 0,
            // 列表数据
            tableData: [],
            // 当前选择实验配比数据
            experimentalRatioTable: [],
            // 线路配比数据
            productFormulaLine: {},
            // 色块字段取值
            productFormulaCount: {
                design_volume_weight: 0,
                practical_volume_weight: 0,
                slump: 0,
                water_binder_ratio: 0,
                sand_rate: 0,
                max_particle_size: 0,
            },
            // 任务单号
            task_number: '',
            // 页面加载状态
            isLoading: false,
            // 是否只读
            isReadOnly: false,
            // 审批类型(1通过，2不通过)
            approvalType: 0,
            // 审批意见弹出
            dialogVisible: false,
            // 意见
            opinion: '',
        };
    },
    created() {},
    mounted() {
        if (this.extr && this.extr.id) {
            if (this.extr.isReadOnly) {
                this.isReadOnly = this.extr.isReadOnly;
            }
            this.task_number = this.extr.taskNumber;
            // 初始化页面数据
            this.getInit();
        }
    },
    methods: {
        // 初始化页面数据
        getInit() {
            this.$axios
                .get('/interfaceApi/production/productformula/formula_approval/info/' + this.extr.id)
                .then(res => {
                    this.productFormula = res.productFormula;
                    this.productFormula.mark_number = this.productFormula.strength_grade_name + this.productFormula.special_require_name;
                    this.mixstationlineData = res.productFormulaLine;
                    this.productFormulaLine = res.productFormulaLine;
                    if (!this.mixstationChecked && this.mixstationlineData.length > 0) {
                        this.mixstationChecked = this.mixstationlineData[0].line_code;
                        this.mixstationChange();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 切换生产机组
        mixstationChange() {
            this.tableData = [];
            this.productFormulaCount = {};
            const tableData = this.productFormulaLine.filter(item => {
                return item.line_code === this.mixstationChecked;
            });
            if (tableData.length > 0) {
                if (this.formulaTypeChecked === 0) {
                    if (tableData[0].product_formula_hjt) {
                        this.processingData(tableData[0].product_formula_hjt);
                    } else {
                        this.tableData = [];
                        this.productFormulaCount = {};
                    }
                } else if (this.formulaTypeChecked === 1) {
                    if (tableData[0].product_formula_sj) {
                        this.processingData(tableData[0].product_formula_sj);
                    } else {
                        this.tableData = [];
                        this.productFormulaCount = {};
                    }
                }
            } else {
                this.tableData = [];
                this.productFormulaCount = {};
            }
        },
        // 数据处理
        processingData(res) {
            const data = JSON.parse(JSON.stringify(res));
            this.experimentalRatioTable = JSON.parse(JSON.stringify(data.productFormulaRelExperimentOutputs));
            this.productFormulaCount = JSON.parse(JSON.stringify(data));
            delete this.productFormulaCount.productFormulaEntityOutputs;
            delete this.productFormulaCount.productFormulaRelExperimentOutputs;

            data.productFormulaRelExperimentOutputs.forEach(z => {
                z.constructionRatio = [];
            });

            const correlationRatio = [];
            data.productFormulaEntityOutputs.forEach(k => {
                data.productFormulaRelExperimentOutputs.forEach(item => {
                    if (item.material_code !== ''
                        && k.material_code === item.material_code
                    ) {
                        correlationRatio.push(k);
                        item.constructionRatio.push(k);
                    } else if ((item.material_code === '' || k.material_code === '')
                        && item.material_child_name !== ''
                        && k.material_child_name === item.material_child_name
                    ) {
                        correlationRatio.push(k);
                        item.constructionRatio.push(k);
                    } else if ((item.material_code === '' || k.material_code === '')
                        && (item.material_child_name === '' || k.material_child_name === '' )
                        && item.stock_category_name !== ''
                        && k.stock_category_name === item.stock_category_name
                    ) {
                        correlationRatio.push(k);
                        item.constructionRatio.push(k);
                    }
                });
            });
            // eslint-disable-next-line max-len
            const newData = data.productFormulaEntityOutputs.filter(item => !correlationRatio.some(ele => ele.material_code === item.material_code && ele.material_child_name === item.material_child_name && ele.stock_category_name === item.stock_category_name));
            // 有工控仓位没有试验配比，试验配比新增一条数据，把工控仓位合并到试验配比数据里
            const unmatchedData = [];
            newData.forEach(item => {
                unmatchedData.push({
                    constructionRatio: [item],
                });
            });
            data.productFormulaRelExperimentOutputs.push(...unmatchedData);
            this.tableData = data.productFormulaRelExperimentOutputs;

            this.calculation1();
        },
        // 工控料仓类型弹出
        JumpChange(row) {
            this.$toast(
                {
                    title: true,
                    text: '工控与ERP关系配置',
                    type: 'eject',
                    width: '13rem',
                    height: 'auto',
                    t_url: 'purchaseManage/centralControlConfig',
                    extr: {
                        mixstation_code: this.$takeTokenParameters('StationId'),
                        line_code: this.mixstationChecked,
                        plcw: row.plcw,
                        scene: 'view',
                    },
                }
            );
        },
        // 生产机组类名
        lineClass(val) {
            if (val) {
                return 'red-class';
            }
            return '';
        },
        // 计算容重、水胶比、砂率
        calculation1() {
            // 容重
            let practical_volume_weight = 0;
            // 细骨料
            let fineAggregate = 0;
            // 粗骨料
            let coarseAggregate = 0;
            // 水
            let water = 0;
            // 粉料
            let powder = 0;
            this.tableData.forEach(item => {
                if (item.constructionRatio && item.constructionRatio.length > 0) {
                    item.constructionRatio.forEach(k => {
                        if (k.product_quantity) {
                            practical_volume_weight += Number(k.product_quantity).toFixed(2) * 100;
                            if (k.stock_category_dictcode === '203') {
                                fineAggregate += Number(k.product_quantity);
                            }
                            if (k.stock_category_dictcode === '204') {
                                coarseAggregate += Number(k.product_quantity);
                            }
                            if (k.stock_category_dictcode === '207') {
                                water += Number(k.product_quantity);
                            }
                            if (
                                k.stock_category_dictcode === '202'
                                || k.stock_category_dictcode === '205-FHL'
                                || k.stock_category_dictcode === '205-GHJ'
                                || k.stock_category_dictcode === '205-FMH'
                                || k.stock_category_dictcode === '205-GH0'
                                || k.stock_category_dictcode === '205-GZF'
                                || k.stock_category_dictcode === '205-KZF'
                                || k.stock_category_dictcode === '205-LZF'
                                || k.stock_category_dictcode === '205-SHF'
                            ) {
                                powder += Number(k.product_quantity);
                            }
                        }
                    });
                } else if (item.product_quantity) {
                    practical_volume_weight += Number(item.product_quantity).toFixed(2) * 100;
                    if (item.stock_category_dictcode === '203') {
                        fineAggregate += Number(item.product_quantity);
                    }
                    if (item.stock_category_dictcode === '204') {
                        coarseAggregate += Number(item.product_quantity);
                    }
                    if (item.stock_category_dictcode === '207') {
                        water += Number(item.product_quantity);
                    }
                    if (
                        item.stock_category_dictcode === '202'
                        || item.stock_category_dictcode === '205-FHL'
                        || item.stock_category_dictcode === '205-GHJ'
                        || item.stock_category_dictcode === '205-FMH'
                        || item.stock_category_dictcode === '205-GH0'
                        || item.stock_category_dictcode === '205-GZF'
                        || item.stock_category_dictcode === '205-KZF'
                        || item.stock_category_dictcode === '205-LZF'
                        || item.stock_category_dictcode === '205-SHF'
                    ) {
                        powder += Number(item.product_quantity);
                    }
                }

            });

            // 砂率
            if (fineAggregate > 0) {
                this.productFormulaCount.sand_rate = (fineAggregate / (fineAggregate + coarseAggregate)).toFixed(2) * 100;
            } else {
                this.productFormulaCount.sand_rate = 0;
            }
            // 水胶比
            if (water > 0 & powder > 0) {
                this.productFormulaCount.water_binder_ratio = (water / powder).toFixed(2) * 100;
            } else {
                this.productFormulaCount.water_binder_ratio = 0;
            }

            // 容重
            this.productFormulaCount.practical_volume_weight = practical_volume_weight / 100;
        },
        // 审批
        approval(type) {
            this.approvalType = type;
            this.dialogVisible = true;
        },
        // 审批取消
        approvalCancelled() {
            this.dialogVisible = false;
            this.$message({
                message: '审批取消！',
                type: 'warning',
            });
        },
        // 审批确认(审批不通过，意见必填)
        approvalConfirmation() {
            if (this.approvalType === 2 && this.opinion === '') {
                this.$message({
                    message: '审批不通过请先填写审批意见！',
                    type: 'warning',
                });
            } else {
                this.confirmRequest();
            }
        },
        // 审批确认请求
        confirmRequest() {
            this.$axios
                .post('/interfaceApi/production/productformula/formula_approval/submit', {
                    id: this.extr.id,
                    approval_status: this.approvalType,
                    approval_opinion: this.opinion,
                })
                .then(() => {
                    this.dialogVisible = false;
                    this.$message({
                        message: '审批完成！',
                        type: 'success',
                    });
                    this.$parent.hide();
                    this.extr._table.updateTable();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};
</script>

<style lang="stylus">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none;
input[type="number"]
    -moz-appearance: textfield;
.matchingApproval
    width 100%
    height 100%
    background #e0e5eb
    position relative
    .content_t
        height 3rem
        width 100%
        overflow hidden
        margin-bottom 0.2rem
        .header_wrapper
            padding 0.2rem 0.2rem 0.1rem 0.2rem
            height 3rem !important
            background #ffffff
            position relative
            table
                width 100%
                height calc(100% - 0.4rem)
                th
                    font-size 0.14rem
                    color #333
                    font-weight 400
                    text-align right
                    padding-left 0.1rem
                td
                    .el-radio
                        &::after
                            transition none !important
                    .el-input__inner
                        color #000
    .content_b
        width 100%
        height calc(100% - 3.2rem)
        background #ffffff
        padding 0.2rem
        overflow hidden
        .headBox
            overflow hidden
        .btn_wrapper
            float right
            .el-button
                // width 0.6rem
                height 0.34rem
                padding 0rem 0.1rem
                font-size 0.16rem
                background: #1577d2;
        .head
            margin-bottom 0.15rem
        .production_wrapper
            &::after
                content ''
                height 0
                line-height 0
                display block
                visibility hidden
                clear both
            .production_span
                font-size 0.16rem
                float left
                line-height 0.34rem
            .radio_list
                float left
                .red-class
                    border 0.01rem solid red
                    .el-radio__label
                        color red
                .el-radio
                    height 0.34rem
                    margin-right: 0.2rem !important;
                    position relative
                    padding 0 0.15rem !important
                    line-height 0.34rem
                    overflow initial
                    .el-radio__input
                        display none
                    .el-radio__label
                        padding 0
                        width 100%
                        height 100%
                        line-height 0.1rem
                    i
                        position absolute
                        top -0.1rem
                        right -0.1rem
                        width 0.2rem
                        height 0.2rem
                        &.toSave
                            background url(./images/save@2x.png) no-repeat
                            background-size 100% 100%
                        &.toSend
                            background url(./images/send@2x.png) no-repeat
                            background-size 100% 100%
                .is-bordered
                    padding 0.1rem
        .main_header
            margin 0.1rem 0
            overflow hidden
            .matching_type_wrapper
                float left
                span
                    font-size 0.16rem
                    float left
                    line-height 0.34rem
                .el-radio__input
                    margin-top 0.1rem
                .radio_list
                    float left
            .statisticalValue
                ul
                    width 12.5rem
                    overflow hidden
                    li
                        width 2rem
                        height 0.3rem
                        border 1px solid rgba(215,215,215,1)
                        float left
                        margin-right 0.1rem
                        &:nth-of-type(6)
                            margin-right 0
                        span
                            font-size 0.16rem
                            line-height 0.28rem !important
                            display block
                            height 0.28rem
                            width 0.85rem
                            float left
                            text-align center
                        p
                            float right
                            line-height 0.28rem !important
                            font-size 0.18rem
                            margin-right 0.1rem
                            i
                                font-size 0.16rem
                                color rgba(215,215,215,1)

                    .sp1
                        span
                            background #5588f12e
                            color #5588F1
                        p
                            color #5588F1
                    .sp2
                        span
                            background #eb656f7a
                            color #EB656F
                        p
                            color #5588F1
                    .sp3
                        span
                            background #21bdcc75
                            color #21BDCC
                        p
                            color #21BDCC
                    .sp4
                        span
                            background #fa843657
                            color #FA8436
                        p
                            color #FA8436
                    .sp5
                        span
                            background #8b6bee63
                            color #8B6BEE
                        p
                            color #8B6BEE
        .content_table
            width 100%
            height 3rem
            border 1px solid #EBEEF5
            .table_header,
            .table_body
                width 100%
                table
                    table-layout fixed
                    word-break break-all
                    width 100%
                    border-collapse collapse
                    th,td
                        font-size 0.14rem
                        text-align center
                        height 0.34rem
                    th
                        background rgb(237, 240, 245)
                        color rgb(2, 39, 130)
                        border-right 1px solid #EBEEF5
                        border-bottom 1px solid #EBEEF5
                        &::last-child
                            border-right none
            .table_body
                max-height calc(100% - 0.34rem)
                overflow-y scroll
                td
                    border-right 1px solid #EBEEF5
                    border-bottom 1px solid #EBEEF5
                    &:last-child
                        border-right none
                    .el-button
                        width auto
                        height auto
                        line-height initial
                        margin 0.05rem
                        font-size 12px
                        color #409EFF
                        background none
                    >i
                        display none
                    >p
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    &.red
                        color red
                    &.green
                        color #30fa3e
                    &.overrun
                        color red
                        i
                            font-size 0.14rem
                            vertical-align top
                            display inline-block
            .active-red
                color red
.mixtureRatioApprovalCommentsDialog
    height auto !important
    .el-dialog__body
        padding 0.15rem
</style>
